<template>
  <v-row justify="center">
    <v-dialog
      v-if="resolutionScreen >= 500"
      v-model="show"
      max-width="700"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title
          >{{ $t("renamedocument.confirmdeleterename") }} <v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35"
            >mdi-file-document-box-remove</v-icon
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-1 pt-1 pb-1 text-center">
          <p class="pl-4 ma-2">
            {{ $t("renamedocument.textconfirmdeleterename") }}
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="$emit('close')">{{
            $t("confirm.closecomfirm")
          }}</v-btn>
          <v-btn
            :color="color.theme"
            :dark="color.darkTheme"
            @click="$emit('deletetemplate', data)"
            >{{ $t("confirm.confirm") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-else
      v-model="show"
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      persistent
    >
      <v-card>
        <v-card-text class="pa-5">
          <v-layout class="pt-2 pb-4">
            <v-flex d-flex justify-center class="ml-6">
              <span :style="headerPage">{{
                $t("renamedocument.confirmdeleterename")
              }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon
                :color="$vuetify.theme.dark ? 'white' : color.theme"
                @click="$emit('close')"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text class="px-4 pt-1 pb-1 text-center" :style="titleText">
          <p class="pl-4 ma-2">
            {{ $t("renamedocument.textconfirmdeleterename") }}
          </p>
        </v-card-text>
        <div class="text-center py-4">
          <v-btn
            :style="btnAction"
            class="mr-6"
            height="40px"
            width="40%"
            color="red"
            outlined
            @click="$emit('close')"
            >{{ $t("confirm.closecomfirm") }}</v-btn
          >
          <v-btn
            height="40px"
            width="40%"
            class="elevation-0"
            :style="btnAction"
            :color="color.theme"
            dark
            @click="$emit('deletetemplate', data)"
            >{{ $t("confirm.confirm") }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  props: ["show", "data"],
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
      "dataCheckPackage",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษร header ของ mobile
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // style อักษรใน dialog ของ mobile
    titleText() {
      return "color:black; font-size: 14px; line-height: 28px;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
  },
};
</script>

<style></style>
